import React from "react";
import { Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import "../styles/AmenitiesSection.css";

// Import internal and external amenities icons here
import earthquakeResistanceIcon_Veda from "../images/earthquakeResistanceIcon.png";
import HiTechFireFightingSystem_Veda from "../images/Hi-tech Fire fighting system.png";
import HiSpeedBrandedElevators_Veda from "../images/Hi-speed Branded Elevators.png";
import ExternalPlumbingBrandedpic_Veda from "../images/Branded-External-Plumbing.png";
import vitrifiedTilesIcon_Veda from "../images/vitrifiedTilesIcon.png";
import CoateskitchenplatformSSsink_Veda from "../images/ccSinkIcon.png";
import Doorleveltileskitchen_Veda from "../images/Door level tiles in kitchen.png";
import ConcealedCopperWiring_Veda from "../images/Concealed copper wiring.png";
import ConcealedPlumbing_Veda from "../images/Concealed plumbing.png";
import BrandedSanitaryWare_Veda from "../images/Branded sanitary ware.png";
import Dadooftilesintoilet_Veda from "../images/Dado of tiles in toilet.png";
import polisheddoorframe_Veda from "../images/polished door frame.png";
import SlidingWindow_Veda from "../images/Sliding Window.png";
import StandbyPowerBackup_Veda from "../images/Standby Power Backup.png";
import VentilatedApartments_Veda from "../images/Ventilated Apartments.png";
import HoursCctvSurveillance_Veda from "../images/24 Hours Cctv Surveillance.png";
import SpaciousDecorativeEntranceLobby_Veda from "../images/Spacious & Decorative Entrance Lobby.png";
import QualityExternalPaintFinish_Veda from "../images/External Paint Finish With Quality Paint.png";
import AmpleCarParking_Veda from "../images/Ample Car Parking.png";
import KitchenTrolley_Veda from "../images/Kitchen Trolley.png";

import clubHouseIcon_Veda from "../images/clubHouseIcon.png";
import chess_Veda from "../images/chess.png";
import IndoorGames_Veda from "../images/Indoor Games.png";
import HightechGym_Veda from "../images/High-tech Gym.png";
import BabyPool_SwimingPool_Veda from "../images/Baby Pool  Swiming Pool.png";
import DeckPool_Veda from "../images/DeckPool.png";
import PottedPlantation_Veda from "../images/Potted Plantation.png";
import LandscapeGarden_Veda from "../images/Landscape Garden.png";
import MovieLawn_Veda from "../images/Movie Lawn.png";
import Amphitheatre_Veda from "../images/Amphitheatre.png";
import ReflexologyPathway_Veda from "../images/Reflexology Pathway.png";
import PlayArea_Veda from "../images/Play Area.png";
import LaundryService_Veda from "../images/Laundry Service.png";
import ParentsSeating_Veda from "../images/Parent’s seating.png";
import SemiGreenWalkway_Veda from "../images/Semi-green Walkway.png";
import GreenTrellis_Veda from "../images/Green Trellis.png";

import RCC_Framed_Structure from "../images/RCC Framed Structure.png";
import Surveillance_Cameras from "../images/Surveillance Cameras.png";
import fire_fighting_system from "../images/Fire Fighting System.png";
import Mechanised_Parking from "../images/Mechanised Parking.png";
import Yoga_Meditation_Zone from "../images/Yoga Meditation Zone.png";
import EV_Charging_Station_Zone from "../images/E-V Charging Station.png";
import Recreational_Facilities from "../images/Recreational Facilities.png";
import Jogging_Track from "../images/Jogging Track.png";
import Senior_Citizen_Area from "../images/Senior Citizen Area.png";
import Special_Netted_Turf from "../images/Special Netted Turf.png"
import Grand_Podium from "../images/Grand Podium on the 3rd Floor.png"
import Plush_Entrance_Lobby from '../images/plush entrance lobby.png'
import Parking_Parking_Tower from '../images/Parking & Parking Tower.png'
import Spacious_Banquet_Hall from '../images/Spacious Banquet Hall.png';
import Gazebo from '../images/gazebo.png'
import Intercom_Video_doorPhone from '../images/Intercom, Video door phone.png'
import MasterBedroom_WardrobeSpace from '../images/Master Bedroom with Wardrobe Space.png'
import Decorative_MainDoor from '../images/Decorative Main Door.png'
import Railing_Window from '../images/Railing for Window.png';
import Modular_Kitchen from '../images/Modular Kitchen.png'
import One_Way_Vision_GlassWindows from '../images/One Way Vision Glass Windows.png'
import JoggingWalking_Track from '../images/JoggingWalking Track.png'

const AmenitiesSection = () => {
  const { projectId } = useParams();

  // Define amenities for each project using the same project ID structure
  const projectAmenities = {
    "nakshatra-veda-i": {
      internal: [
        { src: vitrifiedTilesIcon_Veda, text: "VITRIFIED\nTILES" },
        { src: earthquakeResistanceIcon_Veda, text: "EARTHQUAKE\nRESISTANCE" },
        {
          src: HiTechFireFightingSystem_Veda,
          text: "HI-TECH\nFIRE FIGHTING\nSYSTEM",
        },
        {
          src: HiSpeedBrandedElevators_Veda,
          text: "BRANDED\nHI-SPEED\nELEVATORS",
        },
        {
          src: ExternalPlumbingBrandedpic_Veda,
          text: "BRANDED\nEXTERNAL\nPLUMBING",
        },
        { src: CoateskitchenplatformSSsink_Veda, text: "SS SINK" },
        {
          src: Doorleveltileskitchen_Veda,
          text: "DOOR LEVEL\nTILES IN\nKITCHEN",
        },
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: ConcealedPlumbing_Veda, text: "CONCEALED\nPLUMBING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Dadooftilesintoilet_Veda, text: "DADO OF TILES\nIN TOILET" },
        { src: polisheddoorframe_Veda, text: "POLISHED\nDOOR FRAME" },
        { src: SlidingWindow_Veda, text: "SLIDING\nWINDOW" },
        { src: StandbyPowerBackup_Veda, text: "STANDBY\nPOWER\nBACKUP" },
        { src: VentilatedApartments_Veda, text: "VENTILATED\nAPARTMENTS" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },
        {
          src: SpaciousDecorativeEntranceLobby_Veda,
          text: "DECORATIVE\nENTRANCE\nLOBBY",
        },
        {
          src: QualityExternalPaintFinish_Veda,
          text: "QUALITY\nEXTERNAL\nPAINT FINISH",
        },
        { src: AmpleCarParking_Veda, text: "AMPLE\nCAR PARKING" },
        { src: KitchenTrolley_Veda, text: "KITCHEN\nTROLLEY" },
      ],
      external: [
        { src: clubHouseIcon_Veda, text: "CLUB\nHOUSE" },
        { src: chess_Veda, text: "CHESS" },
        { src: IndoorGames_Veda, text: "INDOOR\nGAMES" },
        { src: HightechGym_Veda, text: "HIGH-TECH\nGYM" },
        { src: BabyPool_SwimingPool_Veda, text: "SWIMMING\nPOOL" },
        { src: DeckPool_Veda, text: "POOL\nDECK " },
        { src: PottedPlantation_Veda, text: "POTTED\nPLANTATION" },
        { src: LandscapeGarden_Veda, text: "LANDSCAPE\nGARDEN" },
        { src: MovieLawn_Veda, text: "MOVIE\nLAWN" },
        { src: Amphitheatre_Veda, text: "AMPHI\nTHEATRE" },
        { src: ReflexologyPathway_Veda, text: "REFLEXOLOGY\nPATHWAY" },
        { src: PlayArea_Veda, text: "KIDS\nPLAY AREA" },
        { src: LaundryService_Veda, text: "LAUNDRY\nSERVICE" },
        { src: ParentsSeating_Veda, text: "PARENTS\nSEATING" },
        { src: SemiGreenWalkway_Veda, text: "SEMI-GREEN\nWALKWAY" },
        { src: GreenTrellis_Veda, text: "GREEN\nTRELLIS" },
      ],
    },
    "nakshatra-aazstha": {
      internal: [
        { src: earthquakeResistanceIcon_Veda, text: "EARTHQUAKE\nRESISTANCE" },
        {
          src: HiTechFireFightingSystem_Veda,
          text: "HI-TECH\nFIRE FIGHTING\nSYSTEM",
        },
        {
          src: HiSpeedBrandedElevators_Veda,
          text: "BRANDED\nHI-SPEED\nELEVATORS",
        },
        {
          src: ExternalPlumbingBrandedpic_Veda,
          text: "BRANDED\nEXTERNAL\nPLUMBING",
        },
        { src: CoateskitchenplatformSSsink_Veda, text: "SS SINK" },
        {
          src: Doorleveltileskitchen_Veda,
          text: "DOOR LEVEL\nTILES IN\nKITCHEN",
        },
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: ConcealedPlumbing_Veda, text: "CONCEALED\nPLUMBING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Dadooftilesintoilet_Veda, text: "DADO OF TILES\nIN TOILET" },
        { src: polisheddoorframe_Veda, text: "POLISHED\nDOOR FRAME" },
        { src: SlidingWindow_Veda, text: "SLIDING\nWINDOW" },
        { src: StandbyPowerBackup_Veda, text: "STANDBY\nPOWER\nBACKUP" },
        { src: VentilatedApartments_Veda, text: "VENTILATED\nAPARTMENTS" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },

        {
          src: SpaciousDecorativeEntranceLobby_Veda,
          text: "DECORATIVE\nENTRANCE\nLOBBY",
        },
        {
          src: QualityExternalPaintFinish_Veda,
          text: "QUALITY\nEXTERNAL\nPAINT FINISH",
        },
        { src: AmpleCarParking_Veda, text: "AMPLE\nCAR PARKING" },
      ],
      external: [
        { src: clubHouseIcon_Veda, text: "GRAND\nCLUB HOUSE" },
        { src: chess_Veda, text: "CHESS" },
        { src: IndoorGames_Veda, text: "INDOOR\nGAMES" },
        { src: HightechGym_Veda, text: "HIGH-TECH\nGYM" },
        { src: BabyPool_SwimingPool_Veda, text: "STEAM\nJACUZZI" },
        { src: DeckPool_Veda, text: "PARTY\nDECK " },
        { src: PottedPlantation_Veda, text: "POTTED\nPLANTATION" },
        { src: LandscapeGarden_Veda, text: "SIT-OUT\nAREA" },
      ],
    },
    "nakshatra-prithvi": {
      internal: [
        { src: earthquakeResistanceIcon_Veda, text: "EARTHQUAKE\nRESISTANCE" },
        {
          src: HiTechFireFightingSystem_Veda,
          text: "HI-TECH\nFIRE FIGHTING\nSYSTEM",
        },
        {
          src: HiSpeedBrandedElevators_Veda,
          text: "BRANDED\nHI-SPEED\nELEVATORS",
        },
        {
          src: ExternalPlumbingBrandedpic_Veda,
          text: "BRANDED\nEXTERNAL\nPLUMBING",
        },

        { src: CoateskitchenplatformSSsink_Veda, text: "SS SINK" },
        {
          src: Doorleveltileskitchen_Veda,
          text: "DOOR LEVEL\nTILES IN\nKITCHEN",
        },
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: ConcealedPlumbing_Veda, text: "CONCEALED\nPLUMBING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Dadooftilesintoilet_Veda, text: "DADO OF TILES\nIN TOILET" },
        { src: polisheddoorframe_Veda, text: "POLISHED\nDOOR FRAME" },
        { src: SlidingWindow_Veda, text: "SLIDING\nWINDOW" },
        { src: StandbyPowerBackup_Veda, text: "STANDBY\nPOWER\nBACKUP" },
        { src: VentilatedApartments_Veda, text: "VENTILATED\nAPARTMENTS" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },

        {
          src: SpaciousDecorativeEntranceLobby_Veda,
          text: "DECORATIVE\nENTRANCE\nLOBBY",
        },
        {
          src: QualityExternalPaintFinish_Veda,
          text: "QUALITY\nEXTERNAL\nPAINT FINISH",
        },
        { src: AmpleCarParking_Veda, text: "AMPLE\nCAR PARKING" },
        { src: RCC_Framed_Structure, text: "RCC\nFRAMED\nSTRUCTURE " },
      ],
      external: [
        { src: HightechGym_Veda, text: "HIGH-TECH\nGYM" },
        { src: BabyPool_SwimingPool_Veda, text: "SWIMMING\nPOOL" },
        { src: Surveillance_Cameras, text: "SURVEILLANCE\nCAMERA" },
        { src: fire_fighting_system, text: "FIRE FIGHTING\nSYSTEM" },
        { src: Mechanised_Parking, text: "MECHANISED\nPARKING" },
      ],
    },
    "nakshatra-gokul": {
      internal: [
        { src: ConcealedPlumbing_Veda, text: "CONCEALED\nPLUMBING" },
        { src: CoateskitchenplatformSSsink_Veda, text: "SS SINK" },
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Dadooftilesintoilet_Veda, text: "DADO OF TILES\nIN TOILET" },
        { src: SlidingWindow_Veda, text: "SLIDING\nWINDOW" },
        { src: StandbyPowerBackup_Veda, text: "STANDBY\nPOWER\nBACKUP" },
        { src: VentilatedApartments_Veda, text: "VENTILATED\nAPARTMENTS" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },
      ],
      external: [
        { src: clubHouseIcon_Veda, text: "GRAND\nCLUB HOUSE" },
        { src: Yoga_Meditation_Zone, text: "YOGA\nZONE" },
        { src: DeckPool_Veda, text: "SEATING\nDECKS " },
        { src: PottedPlantation_Veda, text: "POTTED\nTREES" },
        { src: Amphitheatre_Veda, text: "AMPHI\nTHEATRE" },
        { src: ReflexologyPathway_Veda, text: "REFLEXOLOGY\nPATHWAY" },
        { src: PlayArea_Veda, text: "TODDLERS &\n CHILDREN\nPLAY AREA" },
        { src: LaundryService_Veda, text: "LAUNDRY\nSERVICE" },
        { src: EV_Charging_Station_Zone, text: "E-V CHARGING\nSTATION ZONE" },
        { src: Recreational_Facilities, text: "RECREATIONAL\nFACILITIES" },
        { src: Jogging_Track, text: "JOGGING\nTRACK" },
        { src: Senior_Citizen_Area, text: "SENIOR\nCITIZEN AREA" },
      ],
    },
    "shree-ram-nagar": {
      internal: [
        { src: ConcealedPlumbing_Veda, text: "CONCEALED\nPLUMBING" },
        { src: CoateskitchenplatformSSsink_Veda, text: "SS SINK" },
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Dadooftilesintoilet_Veda, text: "DADO OF TILES\nIN TOILET" },
        { src: StandbyPowerBackup_Veda, text: "STANDBY\nPOWER\nBACKUP" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },



      ],
      external: [
        { src: Yoga_Meditation_Zone, text: "  GYMNASIUM" },
        { src: Gazebo, text: "SITTING AREA &\nGAZEBO" },
        { src: LandscapeGarden_Veda, text: "LANDSCAPE\nGARDEN" },
        { src: PlayArea_Veda, text: "KIDS\nPLAY AREA" },
        { src: Special_Netted_Turf, text: "NESTED\nTURF" },
        { src: Grand_Podium, text: "GRAND\nPODIUM\nON LEVEL 3" },
        { src: Plush_Entrance_Lobby, text: "PLUSH\nENTERNACE\nLOBBY" },
        { src: Spacious_Banquet_Hall, text: "SPACIOUS\nBANQUET\nHALL" },
        { src: Parking_Parking_Tower, text: "PARKING &\nPARKING\nTOWER" },


      ],
    },

    "nakshatra-auris": {
      internal: [
        { src: ConcealedCopperWiring_Veda, text: "CONCEALED\nCOPPER\nWIRING" },
        { src: BrandedSanitaryWare_Veda, text: "BRANDED\nSANITARY\nWARE" },
        { src: Intercom_Video_doorPhone, text: "INTERCOM\nVIDEO DOOR\nPHONE" },
        { src: MasterBedroom_WardrobeSpace, text: "MASTER ROOM\nWITH\nWARDROBE\nSPACE" },
        { src: Decorative_MainDoor, text: "DECORATIVE\nMAIN DOOR" },
        { src: Railing_Window, text: "RAILING\nFOR\nWINDOW" },
        { src: Modular_Kitchen, text: "MODULAR\nKITCHEN" },
        { src: One_Way_Vision_GlassWindows, text: "ONE WAY\nVISION GLASS\nWINDOWS" },

      ],
      external: [
        { src: Yoga_Meditation_Zone, text: "YOGA\nZONE" },
        { src: Senior_Citizen_Area, text: "SENIOR\nCITIZEN AREA" },
        { src: Gazebo, text: "GAZEBO" },
        { src: JoggingWalking_Track, text: "JOGGING\nTRACK" },
        { src: PlayArea_Veda, text: "KIDS\nPLAY AREA" },
        // { src: Special_Netted_Turf, text: "NESTED\nTURF" },


      ],
    },
    "radhe-shyam-heights": {
      internal: [
        { src: earthquakeResistanceIcon_Veda, text: "EARTHQUAKE\nRESISTANCE" },
        { src: RCC_Framed_Structure, text: "RCC\nFRAMED\nSTRUCTURE " },
        { src: vitrifiedTilesIcon_Veda, text: "VITRIFIED\nTILES" },
        {
          src: Doorleveltileskitchen_Veda,
          text: "DOOR LEVEL\nTILES IN\nKITCHEN",
        },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },
        {
          src: SpaciousDecorativeEntranceLobby_Veda,
          text: "DECORATIVE\nENTRANCE\nLOBBY",
        },
        { src: Modular_Kitchen, text: "MODULAR\nKITCHEN" },
      ],
      external: [
        { src: LandscapeGarden_Veda, text: "LANDSCAPE\nGARDEN" },
        { src: Senior_Citizen_Area, text: "SENIOR\nCITIZEN AREA" },
        { src: DeckPool_Veda, text: "POOL\nDECK " },
        { src: SemiGreenWalkway_Veda, text: "GREEN\nWALKWAY" },
        { src: BabyPool_SwimingPool_Veda, text: "SWIMMING\nPOOL" },
        { src: Jogging_Track, text: "FITNESS\nTRACK" },
        { src: Yoga_Meditation_Zone, text: "YOGA\nZONE" },
        { src: PlayArea_Veda, text: "KIDS\nPLAY ZOne" },
        { src: PottedPlantation_Veda, text: "POTTED\nPLANTATION" },
        { src: DeckPool_Veda, text: "PARTY\nDECK " },
      ],
    },
    "nakshatra-nirvana": {
      internal: [
        // { src: vitrifiedTilesIcon_Veda, text: "VITRIFIED\nTILES" },
        { src: HoursCctvSurveillance_Veda, text: "24HRS\nCCTV\nSURVEILLANCE" },
        // { src: KitchenTrolley_Veda, text: "KITCHEN\nTROLLEY" },


       
      ],
      external: [
        { src: Yoga_Meditation_Zone, text: "  GYMNASIUM" },
        { src: IndoorGames_Veda, text: "INDOOR\nGAMES" },
        { src: Jogging_Track, text: "JOGGING\nTRACK" },
        { src: Yoga_Meditation_Zone, text: "YOGA\nZONE" },
        { src: PlayArea_Veda, text: "KIDS\nPLAY ZOne" },
        { src: LandscapeGarden_Veda, text: "LANDSCAPE\nGARDEN" },
      ],
    },
  };

  // Get the amenities for the current project
  const amenities = projectAmenities[projectId] || {
    internal: [],
    external: [],
  };

  return (
    <div className="amenities-section-desktop">
      <Box className="amenities-section-desktop-internal-amenities">
        <div className="amenities-section-desktop-section-title">
          INTERNAL AMENITIES
        </div>
        <Grid container className="amenities-section-desktop-amenities-grid">
          {amenities.internal.map((amenity, index) => (
            <Grid item className="amenity-item" key={index}>
              <img
                src={amenity.src}
                alt={amenity.text}
                className="amenities-section-desktop-amenity-icon"
              />
              <div className="text-container">
                <div className="amenities-section-desktop-amenity-text">
                  {amenity.text.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className="amenities-section-desktop-external-amenities">
        <div className="amenities-section-desktop-section-title">
          EXTERNAL AMENITIES
        </div>
        <div className="amenities-section-desktop-sub-title">
          25+ Luxurious Amenities including
        </div>
        <Grid container className="amenities-section-desktop-amenities-grid">
          {amenities.external.map((amenity, index) => (
            <Grid item className="amenity-item" key={index}>
              <img
                src={amenity.src}
                alt={amenity.text}
                className="amenities-section-desktop-amenity-icon"
              />
              <div className="text-container">
                <div className="amenities-section-desktop-amenity-text">
                  {amenity.text.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
export default AmenitiesSection;
