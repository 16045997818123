import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import "../styles/DesktopMasterpiecesSection.css";

import nakshatraVeda1_Image from "../images/nakshatraVeda1_Mobile.png";
import nakshatraAaztha_Image from "../images/nakshatraAaztha_Mobile.webp";
import nakshatraPrithvi_Image from "../images/nakshatraPrithvi_Mobile.webp";
import nakshatraGokul_Image from "../images/nakshatraGokul_Mobile.webp";
import nakshatraShree_Ram_Nagar_Image from "../images/nakshatraShree_Ram_Nagar_Image.png";
import nakshatraAuris_Image from "../images/nakshatra_Auris_image.png";
import Radheshyam_Heights from "../images/Radheshyam_Heights.jpg";
import Nakshatra_Nirvana from "../images/Nakshatra_Nirvana.jpg";

// import nakshatraAarambh_Image from "../images/nakshatraAarambh_Mobile.webp";

import { useNavigate } from "react-router-dom";

const projectNames = {
  "nakshatra-veda-i": "nakshatra-veda-i",
  "nakshatra-aazstha": "nakshatra-aazstha",
  "nakshatra-prithvi": "nakshatra-prithvi",
  "nakshatra-gokul": "nakshatra-gokul",
  "shree-ram-nagar": "shree-ram-nagar",
  "nakshatra-auris": "nakshatra-auris",
};
const DesktopMasterpiecesSection = () => {
  const projects = [
    {
      id: "nakshatra-veda-i",
      image: nakshatraVeda1_Image,
      title: "Nakshatra\nVEDA I & II",
      location: "Vasai"
    },
    {
      id: "nakshatra-aazstha",
      image: nakshatraAaztha_Image,
      title: "NAKSHATRA AAZSTHA",
      location: "Vasai"
    },
    {
      id: "nakshatra-prithvi",
      image: nakshatraPrithvi_Image,
      title: "NAKSHATRA\nPRITHVI",
      location: "Vasai"
    },
    {
      id: "nakshatra-gokul",
      image: nakshatraGokul_Image,
      title: "NAKSHATRA\nGOKUL",
      location: "Virar"
    },
    {
      id: "shree-ram-nagar",
      image: nakshatraShree_Ram_Nagar_Image,
      title: "SHREE RAM\nNAGAR COMPLEX",
      location: "Virar"
    },
    {
      id: "nakshatra-auris",
      image: nakshatraAuris_Image,
      title: "NAKSHATRA\nAURIS",
      location: "Nala Sopara"
    },
    {
      id: "radhe-shyam-heights",
      image: Radheshyam_Heights,
      title: "RADHESHAYAM\nHEIGHTS",
      location: "Borivali"
    },
    {
      id: "nakshatra-nirvana",
      image: Nakshatra_Nirvana,
      title: "NAKSHATRA\nNIRVAANA",
      location: "Naigaon"
    }
  ];

  const navigate = useNavigate();

  const handleNavigateToProjects = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const fullRows = Math.floor(projects.length / 3);
  const remainingItems = projects.length % 3;
  
  const mainRows = projects.slice(0, fullRows * 3);
  const remainingProjects = projects.slice(fullRows * 3);

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  const rows = chunkArray(mainRows, 3);

  return (
    <div className="desktop-masterpiece-section">
      <div className="desktop-masterpiece-section-title">
        ONGOING PROJECTS
      </div>
      
      {rows.map((row, rowIndex) => (
        <Grid container spacing={3} key={rowIndex} className="desktop-masterpiece-image-grid">
          {row.map((project) => (
            <Grid item xs={4} key={project.id} className="desktop-masterpiece-image-container">
              <div
                className="desktop-masterpiece-image-wrapper"
                onClick={() => handleNavigateToProjects(project.id)}
              >
                <img
                  src={project.image}
                  alt={project.id}
                  className="desktop-masterpiece-project-image"
                />
                <div className="desktop-masterpiece-image-overlay">
                  <div className="desktop-masterpiece-image-title">
                    {project.title}
                  </div>
                  <div className="desktop-masterpiece-image-subtitle">
                    {project.location}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ))}

      {remainingProjects.length > 0 && (
        <Grid 
          container 
          spacing={3} 
          className="desktop-masterpiece-image-grid"
          justifyContent="center"
        >
          {remainingProjects.map((project) => (
            <Grid 
              item 
              xs={4} 
              key={project.id} 
              className="desktop-masterpiece-image-container"
              style={{ maxWidth: '33.33%' }}
            >
              <div
                className="desktop-masterpiece-image-wrapper"
                onClick={() => handleNavigateToProjects(project.id)}
              >
                <img
                  src={project.image}
                  alt={project.id}
                  className="desktop-masterpiece-project-image"
                />
                <div className="desktop-masterpiece-image-overlay">
                  <div className="desktop-masterpiece-image-title">
                    {project.title}
                  </div>
                  <div className="desktop-masterpiece-image-subtitle">
                    {project.location}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default DesktopMasterpiecesSection;
