const FORM_STORAGE_KEY = "userFormData";

export const saveFormData = (formData) => {
  localStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(formData));
};

export const getSavedFormData = () => {
  const storedData = localStorage.getItem(FORM_STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : null;
};
