import React, { forwardRef, useState } from "react";
import "../styles/Footer.css";
import footerLogo from "../images/footer_logo_new.svg";
// import facebookLogo from "../images/facebook_logo.svg";
// import twitterLogo from "../images/twitter_logo.svg";
// import instagramLogo from "../images/instagram_logo.svg";
// import whatsappLogo from "../images/whatsapp_logo.svg";
// import linkedinLogo from "../images/linkedin_logo.svg";
import callImage from "../images/callImage.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/system";

const Footer = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [utmParams,setUtmParams]=useState({});
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const origin = "Jsb Website"
  const queryParameters={
    utm_source: utmSource || "_",
    utm_medium: utmMedium || "_", 
    gad_source: gadSource || "_",
    utm_id: utmId || "_",
    origin: origin
  };

  const areUtmParamsEmpty = Object.values(queryParameters).every(value => value === "_");

  const sourceData = {
    utm_id: queryParameters.utm_id,
  };

  if (areUtmParamsEmpty) {
    sourceData.origin = origin;
  }

  console.log("Query params---",queryParams,utmSource,utmMedium,utmId,gadSource);
  React.useEffect(() => {
    const validKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "origin"
    ];
  
    const utmParams = validKeys.reduce((acc, key) => {
      const value = queryParams.get(key);
      if (value) acc[key] = value.trim(); 
      return acc;
    }, {});
  
    setUtmParams(utmParams);
  }, [location.search]);
  const queryData = Object.fromEntries(queryParams.entries());  

  // Initial form state
  const initialFormState = {
    name: "",
    project: "",
    contactNumber: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    if (name === "name") {
      newValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 100);
    }

    if (name === "contactNumber") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    if (name === "email") {
      newValue = value.replace(/[^a-zA-Z0-9._%+-@]/g, "").slice(0, 100);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, project, contactNumber, email } = formData;

    if (!name) newErrors.name = "Name is required.";
    if (!project) newErrors.project = "Project selection is required.";
    if (!contactNumber) newErrors.contactNumber = "Contact number is required.";
    if (!email) newErrors.email = "Email is required.";

    // Validate name (max 100 characters, no special characters or emojis)
    if (name) {
      if (name.length > 100) {
        newErrors.name = "Name must be less than 100 characters.";
      } else if (!/^[a-zA-Z\s]*$/.test(name)) {
        newErrors.name = "Name cannot contain special characters or emojis.";
      }
    }

    // Validate email format
    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    // Validate contact number (must be exactly 10 digits)
    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) return;
    
    setIsSubmitting(true);
    
    const queryString = new URLSearchParams(queryParameters).toString();
    console.log("queryString:===== ", queryString);

    const newData = {
      first_name: formData.name,
      phone_number: formData.contactNumber,
      email: formData.email,
      project: formData.project,
      utm_source: utmSource,
      utm_medium: utmMedium,
      gad_source: gadSource,
      utm_id: utmId,
      name: formData.name,
      origin: origin
    };

    console.log(newData);
    
    try {
      setFormData(initialFormState);
      fetch(`https://api.xrm.shvx.in/api/v1/lead/create?${queryString}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer etZ96daTEo2M", //Live

          Authorization: "Bearer lXCFVSeMJ7LT", // Live
          // Authorization: "Bearer nKhakeSNkAVc", //dummy for testing
        },
        body: JSON.stringify(newData),
      });

      // fetch(`https://api.shvx.in/api/v1/lead/create?${queryString}`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer eGzFywzn9i0S", // Live
      //   },
      //   body: JSON.stringify(formData),
      // });
      
      // Navigate to thank you page immediately
      navigate("/thank-you?formType=callback");
    } catch (error) {
      console.log("Submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const isHome = location.pathname === "/";
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div id="enquire" className="footer" ref={ref}>
        {isHome && (
          <div id="enquire" className="enquiry-form-wrapper">
            <div className="request-callback-form request-callback-form1">
              <div>
                <h1>ENQUIRE NOW</h1>
              </div>
              <div className="callback-form-container">
                <div className="callback-logo">
                  <img src={callImage} alt="Call Icon" />
                </div>
                <form className="callback-form" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Name*"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                  <select
                    name="project"
                    value={formData.project}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Interested in which project
                    </option>
                    <option value="Nakshatra Veda Phase I">
                      Nakshatra Veda Phase I
                    </option>
                    <option value="Nakshatra Veda Phase II">
                      Nakshatra Veda Phase II
                    </option>
                    <option value="Nakshatra Aazstha">Nakshatra Aazstha</option>
                    <option value="Nakshatra Gokul">Nakshatra Gokul</option>
                    <option value="Nakshatra Prithvi">Nakshatra Prithvi</option>
                    <option value="Nakshatra Aarambh">Nakshatra Aarambh</option>
                    <option value="Shree Ram Nagar Complex">
                      Shree Ram Nagar Complex
                    </option>
                    <option value="Nakshatra Auris">Nakshatra Auris</option>
                    <option value="Nakshatra Auris">Nakshatra Nirvaana</option>
                    <option value="Nakshatra Auris">Radhe Shayam Heights</option>
                  </select>
                  {errors.project && (
                    <div className="error">{errors.project}</div>
                  )}
                  <input
                    type="text"
                    placeholder="Contact Number*"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                  {errors.contactNumber && (
                    <div className="error">{errors.contactNumber}</div>
                  )}
                  <input
                    type="email"
                    placeholder="Email ID*"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <div className="error">{errors.email}</div>}

                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "SUBMITTING..." : "REQUEST CALLBACK"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="footer-top">
          <div className="footer-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.5472397381873!2d72.84258127425424!3d19.171286148976108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6567dcdefe7%3A0x9a51488405a5b8e!2sJSB%20Group!5e0!3m2!1sen!2sin!4v1723435736004!5m2!1sen!2sin"
              width="100%"
              height="298px"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps Location"
            ></iframe>
          </div>
          <div className="footer-contact">
            <h2>ENQUIRE NOW</h2>
            <div className="footer-contact-info">
              <div className="contact-info-group">
                <span className="contact-info-title">Meet us at</span>
                <p>
                  1st Floor, Pratiek Plaza Commercial Tower, Swami Vivekananda
                  Rd, Mahesh Nagar, Goregaon West,
                  <br /> Mumbai, Maharashtra 400104
                </p>
              </div>
              <div className="contact-info-group">
                <span className="contact-info-title">Support</span>
                <p>
                  <a href="mailto:support@jsbgroup.co.in">support@jsbgroup.co.in</a>
                  <br/>
                  <a href="mailto:info@jsbgroup.co.in">info@jsbgroup.co.in</a>
                  <br />
                  {/* <a href="tel:0226542395">022-6542395</a>
                  <br />
                  <a href="tel:+919654354345">+91-96543 54345</a> */}
                </p>
              </div>
            </div>
            <div className="footer-contact-additional">
              <div className="contact-info-group">
                <span className="contact-info-title">Business Hours</span>
                <p>9am - 6pm (Monday - Friday)</p>
              </div>
            </div>
          </div>
        </div>

        {isSmallScreen && (
          <div className="back-btn-div">
            <button onClick={scrollToTop} className="back-to-top-footer">
              Back to Top
            </button>
          </div>
        )}
        <div className="footer-bottom">
          <div className="footer-bottom-left">
            <img
              src={footerLogo}
              alt="JSB Group Logo"
              className="footer-logo"
            />
            <div className="footer-copyright">
              © Copyright 2024 JSB Group. All rights reserved.
            </div>
          </div>
          {/* <div className="footer-social-icons">
          <img src={facebookLogo} alt="Facebook" />
          <img src={twitterLogo} alt="Twitter" />
          <img src={instagramLogo} alt="Instagram" />
          <img src={linkedinLogo} alt="linkedin" />
          <img src={whatsappLogo} alt="WhatsApp" />
        </div> */}
        </div>
      </div>
    </>
  );
});
export default Footer;
