import React from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/ProjectGallery.css";
import leftArrow from "../../images/left-arrow.png";
import rightArrow from "../../images/right-arrow.png";

// Import images
import Nakshatra_Veda1_Gallery_image1 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image1.png";
import Nakshatra_Veda1_Gallery_image2 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image2.png";
import Nakshatra_Veda1_Gallery_image3 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image3.png";
import Nakshatra_Veda1_Gallery_image4 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image4.png";
import Nakshatra_Veda1_Gallery_image5 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image5.png";
import Nakshatra_Veda1_Gallery_image6 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image6.png";
import Nakshatra_Veda1_Gallery_image7 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image7.png";
import Nakshatra_Veda1_Gallery_image8 from "../../images/Mobile_Nakshatra_Veda1_Gallery_image8.png";

import Nakshatra_Aazatha_Gallery_image1 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image1.png";
import Nakshatra_Aazatha_Gallery_image2 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image2.png";
import Nakshatra_Aazatha_Gallery_image3 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image3.png";
import Nakshatra_Aazatha_Gallery_image4 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image4.png";
import Nakshatra_Aazatha_Gallery_image5 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image5.png";
import Nakshatra_Aazatha_Gallery_image6 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image6.png";
import Nakshatra_Aazatha_Gallery_image7 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image7.png";
import Nakshatra_Aazatha_Gallery_image8 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image8.png";
import Nakshatra_Aazatha_Gallery_image9 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image9.png";
import Nakshatra_Aazatha_Gallery_image10 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image10.png";
import Nakshatra_Aazatha_Gallery_image11 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image11.png";
import Nakshatra_Aazatha_Gallery_image12 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image12.png";
import Nakshatra_Aazatha_Gallery_image13 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image13.png";
import Nakshatra_Aazatha_Gallery_image14 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image14.png";
import Nakshatra_Aazatha_Gallery_image15 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image15.png";
import Nakshatra_Aazatha_Gallery_image16 from "../../images/Mobile_Nakshatra_Aazatha_Gallery_image16.png";

import Nakshatra_Gokul_Gallery_image1 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image1.png";
import Nakshatra_Gokul_Gallery_image2 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image2.png";
import Nakshatra_Gokul_Gallery_image3 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image3.png";
import Nakshatra_Gokul_Gallery_image4 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image4.png";
import Nakshatra_Gokul_Gallery_image5 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image5.png";
import Nakshatra_Gokul_Gallery_image6 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image6.png";
import Nakshatra_Gokul_Gallery_image7 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image7.png";
import Nakshatra_Gokul_Gallery_image8 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image8.png";
import Nakshatra_Gokul_Gallery_image9 from "../../images/Mobile_Nakshatra_Gokul_Gallery_image9.png";

import Nakshatra_Aarambh_Gallery_image1 from "../../images/Mobile_Nakshatra_Aarambh_Gallery_image1.png";
import Nakshatra_Aarambh_Gallery_image2 from "../../images/Mobile_Nakshatra_Aarambh_Gallery_image2.png";
import Nakshatra_Aarambh_Gallery_image3 from "../../images/Mobile_Nakshatra_Aarambh_Gallery_image3.png";



import Prithvi_Aarambh_Gallery_image1 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image1.png'
import Prithvi_Aarambh_Gallery_image2 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image2.png'
import Prithvi_Aarambh_Gallery_image3 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image3.png'
import Prithvi_Aarambh_Gallery_image4 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image4.png'
import Prithvi_Aarambh_Gallery_image5 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image5.png'
import Prithvi_Aarambh_Gallery_image6 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image6.png'
import Prithvi_Aarambh_Gallery_image7 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image7.png'
import Prithvi_Aarambh_Gallery_image8 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image8.png'
import Prithvi_Aarambh_Gallery_image9 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image9.png'
import Prithvi_Aarambh_Gallery_image10 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image10.png'
import Prithvi_Aarambh_Gallery_image11 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image11.png'
import Prithvi_Aarambh_Gallery_image12 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image12.png'
import Prithvi_Aarambh_Gallery_image13 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image13.png'
import Prithvi_Aarambh_Gallery_image14 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image14.png'
import Prithvi_Aarambh_Gallery_image15 from '../../images/Mobile_Prithvi_Aarambh_Gallery_image15.png'

import Mobile_ShreeRamNagar_Gallery_image1 from '../../images/Mobile_ShreeRamNagar_Gallery_image1.png'
import Mobile_ShreeRamNagar_Gallery_image2 from '../../images/Mobile_ShreeRamNagar_Gallery_image2.png'
import Mobile_ShreeRamNagar_Gallery_image3 from '../../images/Mobile_ShreeRamNagar_Gallery_image3.png'
import Mobile_ShreeRamNagar_Gallery_image4 from '../../images/Mobile_ShreeRamNagar_Gallery_image4.png'
import Mobile_ShreeRamNagar_Gallery_image5 from '../../images/Mobile_ShreeRamNagar_Gallery_image5.png'
import Mobile_ShreeRamNagar_Gallery_image6 from '../../images/Mobile_ShreeRamNagar_Gallery_image6.png'
import Mobile_ShreeRamNagar_Gallery_image7 from '../../images/Mobile_ShreeRamNagar_Gallery_image7.png'
// Custom Arrow Components

import Mobile_Nakshatra_Auris_Gallery_image1 from '../../images/Mobile_Nakshatra_Auris_Gallery_image1.png'
import Mobile_Nakshatra_Auris_Gallery_image2 from '../../images/Mobile_Nakshatra_Auris_Gallery_image2.png'

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="project-gallery-mobile-arrow project-gallery-mobile-right-arrow"
      onClick={onClick}
    >
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="project-gallery-mobile-arrow project-gallery-mobile-left-arrow"
      onClick={onClick}
    >
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

const ProjectGalleryMobile = () => {
  const { projectId } = useParams();

  // Define image sets for each project
  const imageSets = {
    "nakshatra-veda-i": [
      Nakshatra_Veda1_Gallery_image1,
      Nakshatra_Veda1_Gallery_image2,
      Nakshatra_Veda1_Gallery_image3,
      Nakshatra_Veda1_Gallery_image4,
      Nakshatra_Veda1_Gallery_image5,
      Nakshatra_Veda1_Gallery_image6,
      Nakshatra_Veda1_Gallery_image7,
      Nakshatra_Veda1_Gallery_image8,
    ],
    "nakshatra-aazstha": [
      Nakshatra_Aazatha_Gallery_image2,
      Nakshatra_Aazatha_Gallery_image1,
      Nakshatra_Aazatha_Gallery_image3,
      Nakshatra_Aazatha_Gallery_image4,
      Nakshatra_Aazatha_Gallery_image5,
      Nakshatra_Aazatha_Gallery_image6,
      Nakshatra_Aazatha_Gallery_image7,
      Nakshatra_Aazatha_Gallery_image8,
      Nakshatra_Aazatha_Gallery_image9,
      Nakshatra_Aazatha_Gallery_image10,
      Nakshatra_Aazatha_Gallery_image11,
      Nakshatra_Aazatha_Gallery_image12,
      Nakshatra_Aazatha_Gallery_image13,
      Nakshatra_Aazatha_Gallery_image14,
      Nakshatra_Aazatha_Gallery_image15,
      Nakshatra_Aazatha_Gallery_image15,
      Nakshatra_Aazatha_Gallery_image16,
    ],
    "nakshatra-gokul": [
      Nakshatra_Gokul_Gallery_image1,
      Nakshatra_Gokul_Gallery_image2,
      Nakshatra_Gokul_Gallery_image3,
      Nakshatra_Gokul_Gallery_image4,
      Nakshatra_Gokul_Gallery_image5,
      Nakshatra_Gokul_Gallery_image6,
      Nakshatra_Gokul_Gallery_image7,
      Nakshatra_Gokul_Gallery_image8,
      Nakshatra_Gokul_Gallery_image9
    ],
    "nakshatra-prithvi": [
      Prithvi_Aarambh_Gallery_image1,
      Prithvi_Aarambh_Gallery_image2,
      Prithvi_Aarambh_Gallery_image3,
      Prithvi_Aarambh_Gallery_image4,
      Prithvi_Aarambh_Gallery_image5,
      Prithvi_Aarambh_Gallery_image6,
      Prithvi_Aarambh_Gallery_image7,
      Prithvi_Aarambh_Gallery_image8,
      Prithvi_Aarambh_Gallery_image9,
      Prithvi_Aarambh_Gallery_image10,
      Prithvi_Aarambh_Gallery_image11,
      Prithvi_Aarambh_Gallery_image12,
      Prithvi_Aarambh_Gallery_image13,
      Prithvi_Aarambh_Gallery_image14,
      Prithvi_Aarambh_Gallery_image15,
    ],
    "nakshatra-aarambh": [
      Nakshatra_Aarambh_Gallery_image1,
      Nakshatra_Aarambh_Gallery_image2,
      Nakshatra_Aarambh_Gallery_image3,
    ],
    "shree-ram-nagar":[
      Mobile_ShreeRamNagar_Gallery_image1,
      Mobile_ShreeRamNagar_Gallery_image2,
      Mobile_ShreeRamNagar_Gallery_image3,
      Mobile_ShreeRamNagar_Gallery_image4,
      Mobile_ShreeRamNagar_Gallery_image5,
      Mobile_ShreeRamNagar_Gallery_image6,
      Mobile_ShreeRamNagar_Gallery_image7
    ],
     "nakshatra-auris":[
      Mobile_Nakshatra_Auris_Gallery_image1,
      Mobile_Nakshatra_Auris_Gallery_image2
      
     ],
     "radhe-shyam-heights":[
      Mobile_Nakshatra_Auris_Gallery_image1,
      Mobile_Nakshatra_Auris_Gallery_image2
     ],
     "nakshatra-nirvana":[
      Mobile_Nakshatra_Auris_Gallery_image1,
      Mobile_Nakshatra_Auris_Gallery_image2
     ]
  };

  const images = imageSets[projectId] || [];

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="project-gallery-mobile-container">
      <h2 className="project-gallery-mobile-title">
        PROJECT <br /> GALLERY
      </h2>

      <div className="project-gallery-mobile-carousel">
        <Slider {...settings}>
          {images.length > 0 ? (
            images.map((image, index) => (
              <div
                className="project-gallery-mobile-image-container"
                key={index}
              >
                <img
                  src={image}
                  alt={`Project ${index + 1}`}
                  className="project-gallery-mobile-image"
                />
              </div>
            ))
          ) : (
            <div>No images available for this project.</div>
          )}
        </Slider>
      </div>
    </div>
  );
};

export default ProjectGalleryMobile;
