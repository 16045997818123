import React, { useState, useEffect } from "react";
import "../styles/DownloadBrochure.css";
import { useLocation } from "react-router-dom";
import { saveFormData, getSavedFormData } from "../utils/localStorage";

const DownloadBrochureForm = ({ brochureUrl }) => {
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [utmParams, setUtmParams] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const queryParameters = {
    utm_source: utmSource || "_",
    utm_medium: utmMedium || "_",
    gad_source: gadSource || "_",
    utm_id: utmId || "_",
  };
  // console.log(
  //   "Query params---",
  //   queryParams,
  //   utmSource,
  //   utmMedium,
  //   utmId,
  //   gadSource
  // );

  React.useEffect(() => {
    const validKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
    ];

    const utmParams = validKeys.reduce((acc, key) => {
      const value = queryParams.get(key);
      if (value) acc[key] = value.trim();
      return acc;
    }, {});

    setUtmParams(utmParams);
  }, [location.search]);

  useEffect(() => {
    const storedData = getSavedFormData();
    if (storedData) {
      setIsSubmitted(true);
    }
  }, []);
  
  const queryData = Object.fromEntries(queryParams.entries());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, contactNumber, email } = formData;

    if (!name.trim()) newErrors.name = "Name is required.";
    if (!contactNumber.trim())
      newErrors.contactNumber = "Contact number is required.";
    if (!email.trim()) newErrors.email = "Email is required.";

    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async  (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    saveFormData(formData);
    setIsSubmitted(true);
    e.preventDefault();
    if (!validateForm()) return;
    const payload = {
      ...formData,
      ...queryData,
    };
    const queryString = new URLSearchParams(queryParameters).toString();
    console.log("queryString:===== ", queryString);

    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const projectName = pathSegments.includes("projects")
      ? pathSegments[pathSegments.indexOf("projects") + 1]
      : "";

    if (!projectName) {
      console.error("Project name could not be determined from URL.");
      alert("Invalid URL: Unable to determine project name.");
      return;
    }

    const data = {
      ...formData,
      projectName: projectName.replace(/-/g, " "),
    };
    const newData = {
      first_name: formData.name,
      phone_number: formData.contactNumber,
      email: formData.email,
      project: projectName.replace(/-/g, " "),
      utm_source: utmSource,
      utm_medium: utmMedium,
      gad_source: gadSource,
      utm_id: utmId,
      name: formData.name,
    }
    
  
    console.log("Payload sent to API:", data);
    fetch(`https://api.xrm.shvx.in/api/v1/lead/create?${queryString}`, {
  method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer etZ96daTEo2M", //Live
          Authorization: "Bearer lXCFVSeMJ7LT", //Live
          // Authorization: "Bearer nKhakeSNkAVc", //dummy for testing
        },
        body: JSON.stringify(newData),
      })
    //   .catch(() => console.log("Submission failed"));

    // fetch(`https://api.shvx.in/api/v1/lead/create?${queryString}`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer eGzFywzn9i0S", //Live
    //     // Authorization: "Bearer 0eUjiXPjrSYy", //dummy for testing ---jyoti firestore
    //   },
    //   body: JSON.stringify(data),
    // })
      .then((response) => response.json())
      .then(async (response) => {
        console.log("API Response:", response);
        if (response.success) {
          console.log("Data submitted successfully:", response);
          console.log("UTM Parameters:", utmParams);
  
          if (brochureUrl) {
            console.log("Data submitted successfully",brochureUrl);
            
            const link = await document.createElement("a");
            link.href = await  brochureUrl;
            link.target = "_blank"; 
            link.download = "Brochure.pdf"; 
            await document.body.appendChild(link); 
            await link.click(); 
            document.body.removeChild(link); 
          } else {
            console.error("Brochure URL is not valid.");
          }
  
          if (window.gtag_report_conversion) {
            console.log("Triggering Google Ads Conversion");
            window.gtag_report_conversion();
            console.log("Google Ads Conversion Triggered");
          } else {
            console.error("Google Ads Conversion function is not defined.");
          }
  
          // Reset the form fields
          setFormData({
            name: "",
            contactNumber: "",
            email: "",
          });
  
        } else {
          console.error("Failed to submit data:", response);
          alert(response.message || "Failed to submit data. Please try again.");
        }
  
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        alert("An error occurred. Please try again.");
      });
  };

  const handleDownload = () => {
    if (!brochureUrl) {
      alert("Brochure URL is not available.");
      return;
    }
    const link = document.createElement("a");
    link.href = brochureUrl;
    link.target = "_blank";
    link.download = "Brochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="download-brochure-form">
      {isSubmitted ? (
        <div className="is-submitted">
        <button onClick={handleDownload} className="download-button">
          Download Brochure
        </button>
        </div>
      ) : (
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          name="name"
          placeholder="Full Name *"
          value={formData.name}
          onChange={handleChange}
          className={`form-input ${errors.name ? "input-error" : ""}`}
        />
        {errors.name && <small className="error-text">{errors.name}</small>}

        <input
          type="text"
          name="contactNumber"
          placeholder="Mobile Number *"
          value={formData.contactNumber}
          onChange={handleChange}
          className={`form-input ${errors.contactNumber ? "input-error" : ""}`}
        />
        {errors.contactNumber && (
          <small className="error-text">{errors.contactNumber}</small>
        )}

        <input
          type="email"
          name="email"
          placeholder="Email ID *"
          value={formData.email}
          onChange={handleChange}
          className={`form-input ${errors.email ? "input-error" : ""}`}
        />
        {errors.email && <small className="error-text">{errors.email}</small>}

        <button type="submit" className="download-button">
          Download Brochure
        </button>
      </form>
      )}
    </div>
  );
};

export default DownloadBrochureForm;
