import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import logo from "../../images/JSB_Logo_latest.png";
import "../../styles/ProjectPageCarousel.css";

// import Mobile_Nakshatra_veda1_image1 from "../../images/Carousel_Mobile_Nakshatra_veda1_image1.jpg";
// import Mobile_Nakshatra_veda1_image2 from "../../images/Carousel_Mobile_Nakshatra_veda1_image2.jpg";
// import Mobile_Nakshatra_veda1_image3 from "../../images/Carousel_Mobile_Nakshatra_veda1_image3.jpg";
// import Mobile_Nakshatra_veda1_image4 from "../../images/Carousel_Mobile_Nakshatra_veda1_image4.jpg";

import Mobile_Nakshatra_veda1_image1 from "../../images/Carousel_Mobile_Nakshatra_veda1_elevation_image1.jpg";
import Mobile_Nakshatra_veda1_image2 from "../../images/Carousel_Mobile_Nakshatra_veda1_elevation_image2.jpg";
import Mobile_Nakshatra_veda1_image3 from "../../images/Carousel_Mobile_Nakshatra_veda1_elevation_image3.jpg";
import Mobile_Nakshatra_veda1_image4 from "../../images/Carousel_Mobile_Nakshatra_veda1_amenities_image4.jpg";
import Mobile_Nakshatra_veda1_image5 from "../../images/Carousel_Mobile_Nakshatra_veda1_amenities_image5.jpg";
import Mobile_Nakshatra_veda1_image6 from "../../images/Carousel_Mobile_Nakshatra_veda1_amenities_image6.jpg";
// import Mobile_Nakshatra_veda1_image7 from "../../images/Carousel_Mobile_Nakshatra_veda1_interior_image7.jpg";
// import Mobile_Nakshatra_veda1_image8 from "../../images/Carousel_Mobile_Nakshatra_veda1_interior_image8.jpg";
// import Mobile_Nakshatra_veda1_image9 from "../../images/Carousel_Mobile_Nakshatra_veda1_interior_image9.jpg";

import Mobile_Nakshatra_Aazstha_image1 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image1.png";
// import Mobile_Nakshatra_Aazstha_image2 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image2.png";
// import Mobile_Nakshatra_Aazstha_image3 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image3.png";
import Mobile_Nakshatra_Aazstha_image4 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image4.png";
import Mobile_Nakshatra_Aazstha_image5 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image5.png";
import Mobile_Nakshatra_Aazstha_image6 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image6.png";
import Mobile_Nakshatra_Aazstha_image7 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image7.png";
import Mobile_Nakshatra_Aazstha_image8 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image8.png";
import Mobile_Nakshatra_Aazstha_image9 from "../../images/Carousel_Mobile_Nakshatra_Aazstha_image9.png";

// import Carousel_Mobile_Nakshatra_Gokul_image1 from '../../images/Carousel_Mobile_Nakshatra_Gokul_image1.png'
import Carousel_Mobile_Nakshatra_Gokul_image1 from "../../images/Carousel_Mobile_Nakshatra_Gokul_new_image1.png";
import Carousel_Mobile_Nakshatra_Gokul_image2 from "../../images/Carousel_Mobile_Nakshatra_Gokul_image2.png";
import Carousel_Mobile_Nakshatra_Gokul_image3 from "../../images/Carousel_Mobile_Nakshatra_Gokul_image3.png";
import Carousel_Mobile_Nakshatra_Gokul_image4 from "../../images/Carousel_Mobile_Nakshatra_Gokul_image4.png";
import Carousel_Mobile_Nakshatra_Gokul_image5 from "../../images/Carousel_Mobile_Nakshatra_Gokul_image5.png";

// import Carousel_Mobile_Nakshatra_Prithvi_image1 from '../../images/Carousel_Mobile_Nakshatra_Prithvi_image1.png'
// import Carousel_Mobile_Nakshatra_Prithvi_image2 from '../../images/Carousel_Mobile_Nakshatra_Prithvi_image2.png'
// import Carousel_Mobile_Nakshatra_Prithvi_image3 from '../../images/Carousel_Mobile_Nakshatra_Prithvi_image3.png'

import Carousel_Mobile_Nakshatra_Prithvi_image1 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image1.png";
import Carousel_Mobile_Nakshatra_Prithvi_image2 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image2.png";
import Carousel_Mobile_Nakshatra_Prithvi_image3 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image3.png";
import Carousel_Mobile_Nakshatra_Prithvi_image4 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image4.png";
import Carousel_Mobile_Nakshatra_Prithvi_image5 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image5.png";
import Carousel_Mobile_Nakshatra_Prithvi_image6 from "../../images/Carousel_Mobile_Nakshatra_Prithvi_new_image6.png";

import Carousel_Mobile_Nakshatra_Aarambhi_image1 from "../../images/Carousel_Mobile_Nakshatra_Aarambhi_image1.png";

import Carousel_Mobile_ShreeRamNagar_image1 from "../../images/Carousel_Mobile_ShreeRamNagar_image1.png";

import Mobile_Carousel_Nakshatra_Auris_image1 from "../../images/Mobile_Carousel_Nakshatra_Auris_image1.png";
import Mobile_Carousel_Nakshatra_Auris_image2 from "../../images/Mobile_Carousel_Nakshatra_Auris_image2.png";
import DownloadBrochureForm from "../DownloadBrochure";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProjectPageCarouselMobile = () => {
  const theme = useTheme();
  const { projectId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  // Define images and details based on projectId
  const projectData = {
    "nakshatra-veda-i": {
      images: [
        Mobile_Nakshatra_veda1_image1,
        Mobile_Nakshatra_veda1_image2,
        Mobile_Nakshatra_veda1_image3,
        Mobile_Nakshatra_veda1_image4,
        Mobile_Nakshatra_veda1_image5,
        Mobile_Nakshatra_veda1_image6,
        // Mobile_Nakshatra_veda1_image7,
        // Mobile_Nakshatra_veda1_image8,
        // Mobile_Nakshatra_veda1_image9
      ],
      details: [
        { label: "Project Area", value: "6 Acres" },
        { label: "Configuration", value: "1BHK , 2BHK , <br/>3BHK" },
        { label: "Sizes", value: "430 sq.ft.<br/>795 sq.ft." },
        { label: "Project Size", value: "6 Buildings" },
        { label: "Launch Date", value: "Dec 2026" },
        { label: "Avg. Price", value: "42L onwards" },
        {
          label: "Possession Status",
          value:
            "Rera Possession - December 2025<br/>Target Possession - December 2023",
        },
      ],
    },
    "nakshatra-aazstha": {
      images: [
        Mobile_Nakshatra_Aazstha_image1,
        // Mobile_Nakshatra_Aazstha_image2,
        // Mobile_Nakshatra_Aazstha_image3,
        Mobile_Nakshatra_Aazstha_image4,
        Mobile_Nakshatra_Aazstha_image5,
        Mobile_Nakshatra_Aazstha_image6,
        Mobile_Nakshatra_Aazstha_image7,
        Mobile_Nakshatra_Aazstha_image8,
        Mobile_Nakshatra_Aazstha_image9,
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
      ],
    },

    "nakshatra-gokul": {
      images: [
        Carousel_Mobile_Nakshatra_Gokul_image1,
        Carousel_Mobile_Nakshatra_Gokul_image2,
        Carousel_Mobile_Nakshatra_Gokul_image3,
        Carousel_Mobile_Nakshatra_Gokul_image4,
        Carousel_Mobile_Nakshatra_Gokul_image5,
      ],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft - 518 sq.ft" },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "46.82 L onwards" },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
      ],
    },

    "nakshatra-prithvi": {
      images: [
        Carousel_Mobile_Nakshatra_Prithvi_image1,
        Carousel_Mobile_Nakshatra_Prithvi_image2,
        Carousel_Mobile_Nakshatra_Prithvi_image3,
        Carousel_Mobile_Nakshatra_Prithvi_image4,
        Carousel_Mobile_Nakshatra_Prithvi_image5,
        Carousel_Mobile_Nakshatra_Prithvi_image6,
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
      ],
    },

    "nakshatra-aarambh": {
      images: [Carousel_Mobile_Nakshatra_Aarambhi_image1],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft. - 932 sq.ft." },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.57 K/sq.ft - 10.07K / sq.ft." },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
      ],
    },
    "shree-ram-nagar": {
      images: [Carousel_Mobile_ShreeRamNagar_image1],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Avg. Price", value: "Price on request" },
        { label: "Possession Starts", value: "December 2026" },
        {
          label: "Configurations",
          value: "1, 2, 3 BHKs, Villas, Commercial Spaces",
        },
        { label: "" },
      ],
    },
    "nakshatra-auris": {
      images: [
        Mobile_Carousel_Nakshatra_Auris_image1,
        Mobile_Carousel_Nakshatra_Auris_image2,
      ],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Avg. Price", value: "Price on request" },
        { label: "Possession Starts", value: "December 2026" },
        {
          label: "Configurations",
          value: "1, 2, 3 BHKs, Villas, Commercial Spaces",
        },
        { label: "" },
      ],
    },
    "radhe-shyam-heights": {
      images: [
        Mobile_Carousel_Nakshatra_Auris_image1,
        Mobile_Carousel_Nakshatra_Auris_image2,
      ],
      details: [
        { label: "Project Area", value: "22 Acres" },
        { label: "Configuration", value: "1BHK, 2BHK <br/> 3BHK" },
        { label: "Sizes", value: "392 sq.ft (1BHK) <br/> 497 sq.ft (2 BHK) <br/> 752 sq.ft (3 BHK)" },
        { label: "Project Size", value: "1 Towers" },
        { label: "Launch Date", value: "December 2027" },
        {
          label: "Possession Status",
          value:
            "Under Construction",
        },
        { label: "Avg. Price", value: "Price On Request" },
      ],
    },
    "nakshatra-nirvana": {
      images: [
        Mobile_Carousel_Nakshatra_Auris_image1,
        Mobile_Carousel_Nakshatra_Auris_image2,
      ],
      details: [
        { label: "Project Area", value: "1 Acres" },
        { label: "Configuration", value: "1BHK And 2BHK " },
        { label: "Sizes", value: "339sq.ft (1BHK) <br/> 473 sq.ft (2 BHK)" },
        { label: "Project Size", value: "3 towers" },
        { label: "Launch Date", value: "June 2027" },
        {
          label: "Possession Status",
          value:
            "Under Construction",
        },
        { label: "Avg. Price", value: "₹38.04L" },
      ],
    },
  };
  const brochureUrls = {
    "nakshatra-veda-i":
      "https://drive.google.com/file/d/1SAxJRukjCJcCj5l1vuVCbTSU-9z8LLWk/view",
    "nakshatra-aazstha":
      "https://drive.google.com/file/d/1DTuf6fhQDhviFkHR8H_O4P7JPqbCco4C/view?usp=sharing",
    "nakshatra-gokul":
      "https://drive.google.com/file/d/1cj2wMpgOr5YAw-hbpjSQ-L9uPNbsvaIa/view?usp=sharing",
    "nakshatra-prithvi":
      "https://drive.google.com/file/d/1Gyhotnekk9L80ZSyvgc1F1NbN1TeBZEF/view?usp=sharing",
    "nakshatra-aarambh":
      "https://drive.google.com/file/d/1rKi5iXb7l5T41cYq1ze-pq-Sx9GERtHz/view?usp=sharing",
    "shree-ram-nagar":
      "https://drive.google.com/file/d/1hQiSQ9UA5jjaSTYrIk7uJVsx-Iv9Re7o/view?usp=sharing",
    "nakshatra-auris":
      "https://drive.google.com/file/d/1ThFkE_mG0YZKr1y9JSVs8pYAWguU9RxQ/view?usp=sharing",
  };

  // Get the brochure URL for the current project
  const brochureUrl = brochureUrls[projectId];

  const data = projectData[projectId] || { images: [], details: [] };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="mobile-ProjectPageCarousel-container">
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        springConfig={{
          duration: '0.5s', 
          easeFunction: 'ease-in-out',
          delay: '0s',
        }}
        className="mobile-ProjectPageCarousel-carousel"
      >
        {data.images.map((image, index) => (
          <div key={index} className="mobile-ProjectPageCarousel-carousel-item">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="mobile-ProjectPageCarousel-carousel-image"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <DownloadBrochureForm brochureUrl={brochureUrl} />
      <header className="mobile-header">
        <Link to="/" className="mobile-logo-link">
          <img src={logo} alt="Logo" className="mobile-logo" />
        </Link>
      </header>
      <Box className="mobile-ProjectPageCarousel-details-section">
        <Grid container className="grid-container">
          {data.details.slice(0, 6).map((detail, index) => (
            <Grid
              item
              xs={6}
              key={index}
              className={`mobile-ProjectPageCarousel-details-item ${
                index % 2 === 0 ? "left" : "right"
              }`}
            >
              <p className="mobile-ProjectPageCarousel-details-item-title">
                <strong>{detail.label}</strong>
              </p>
              <div
                className="mobile-ProjectPageCarousel-details-item-description"
                dangerouslySetInnerHTML={{ __html: detail.value }}
              />
            </Grid>
          ))}
          {data.details.length > 6 && (
            <Grid
              item
              xs={12}
              className="mobile-ProjectPageCarousel-details-item full-width"
            >
              <p className="mobile-ProjectPageCarousel-details-item-title">
                <strong>{data.details[6].label}</strong>
              </p>
              <div
                className="mobile-ProjectPageCarousel-details-item-description"
                dangerouslySetInnerHTML={{ __html: data.details[6].value }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default ProjectPageCarouselMobile;
